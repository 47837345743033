input[type='password'],
input.webkit-password {
  letter-spacing: 2px;
}
input.webkit-password {
  -webkit-text-security: disc;
}

/* hide arrows on number fields
   https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

label {
  margin-bottom: 0; // 2
}

.not-interactive {
  pointer-events: none; //hack way to get an element read-only
}

legend + p {
  line-height: 1.2;
  margin: 0;
}
/* cross-browser field alignment */
.mat-form-field {
  line-height: 1.2;
}
.mat-form-field-infix:not(.opt-in *) {
  padding: 5px 0;
  width: auto !important;
}
.mat-form-field-suffix:not(.opt-in *) {
  position: absolute !important;
  top: 19px;
  right: 0;
}

.mat-form-field-type-mat-select.faux-disable {
  pointer-events: none;
  .mat-select-arrow-wrapper {
    visibility: hidden;
  }
}

.mat-form-field.readonly,
.mat-form-field[inactive],
.mat-form-field-appearance-standard.mat-form-field-disabled {
  &:not(.opt-in *) {
    input {
      color: rgba(0, 0, 0, 0.38);
    }
    .mat-form-field-ripple {
      background-color: transparent;
    }
    .mat-form-field-underline {
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
      background-size: 4px 100%;
      background-repeat: repeat-x;
      background-color: transparent !important;
    }
    .mat-form-field-label {
      color: $label-gray;
    }
  }
}

.mat-form-field[gray-textarea] {
  textarea {
    border: 1px solid $muted-text;
    background-color: $light-gray-alt;
    padding: 5px;
  }
  &.mat-form-field-appearance-standard .mat-form-field-underline {
    display: none;
  }
}

.mat-form-field.no-underline {
  &.mat-form-field-appearance-standard .mat-form-field-underline {
    //when appearance is appearance="standard" (used for disabled and legacy theme)
    //hide little bar under text box
    display: none;
  }
}

.mat-form-field.no-label {
  .mat-form-field-infix {
    border-top: 0;
  }
}
textarea {
  min-height: 5em;
}
.highlight {
  font-weight: 700;
}
.mat-error {
  line-height: 1.2;
}
.error {
  color: $error !important;
}
.error-border {
  border-color: $error !important;
}
.success {
  color: $green !important;
}
.dark-blue {
  @include dark-color;
}
.muted-text {
  color: $muted-text;
}
.pointer {
  cursor: pointer;
}
.clamp-2 {
  max-height: 2.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.display-island {
  margin: 10px 0 20px 0;
  padding: 20px;
  border-radius: 10px;
  background-color: $form-bg;
}

.mat-form-field-appearance-standard .mat-form-field-underline:not(.opt-in *) {
  height: 2px !important;
  background-color: $underline-gray !important;
}

.mat-divider:not(.opt-in *) {
  border-top-width: 2px !important;
  border-color: $line-gray !important;
}

.mat-form-field-infix + .mat-form-field-suffix:not(.opt-in *) {
  color: rgba(0, 0, 0, 0.54);
}

.mat-form-field-subscript-wrapper {
  margin-top: 2px;
}

.mat-button:not(.opt-in *) {
  font-weight: 600;
  &.with-border {
    border-width: 1px;
    border-style: solid;
    &.mat-primary {
      @include dark-border-color;
    }
  }
  &.auto {
    line-height: 2;
    min-width: auto;
  }
  &.alt,
  &.mat-alt {
    @include accent-color-and-bg;
  }
  &.alt-cancel,
  &.mat-alt-cancel {
    color: white;
    background-color: $alt-cancel-button-bg;
  }
  &.mat-inverse,
  &.mat-inverse-cancel {
    color: $dark-text;
    background-color: white;
    border: 1px solid $underline-gray;
  }
  &.mat-inverse:not([disabled]):hover {
    color: white;
    @include dark-border-color;
    @include dark-bg;
  }
  &.mat-inverse-cancel:hover {
    color: white;
    background-color: $underline-gray;
  }
  &.full {
    width: 100%;
  }
  &.large {
    @extend %mat-button-large;
  }
  &.align-top {
    vertical-align: text-top;
  }
  & + .mat-button {
    margin-left: 12px;
  }
  & + .mat-button.auto {
    margin-left: 6px;
  }
}

.mat-menu-content > .mat-button + .mat-button {
  margin-left: 0px;
}

.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
  &:not(.opt-in *) {
    color: white;
    @include dark-bg;
    &[disabled] {
      opacity: 25%;
      color: white;
      cursor: not-allowed;
    }
  }
}

.mat-button.mat-accent,
.mat-icon-button.mat-accent,
.mat-stroked-button.mat-accent {
  &:not(.opt-in *) {
    color: white;
    background-color: $dark-button-bg;
  }
}

a.mat-button {
  &:hover {
    text-decoration: none;
  }
}

.mat-form-field-appearance-fill .mat-select-arrow-wrapper,
.mat-form-field-appearance-standard .mat-select-arrow-wrapper {
  transform: none !important;
}

form {
  .mat-input-element,
  .mat-select {
    font-size: 16px !important;
  }
}

.mat-progress-bar {
  position: absolute !important;
  z-index: 4;
  height: 8px !important;
  .mat-progress-bar-fill::after {
    background-color: $progress-fill;
  }
  .mat-progress-bar-buffer {
    background-color: $progress-bg;
  }
}

.mat-progress-spinner {
  margin: 40px auto;
  circle {
    stroke: $dark-blue;
    stroke: $dark-color;
  }
}

fieldset {
  border: none;

  .mat-form-field-label {
    @include dark-color;
  }

  legend {
    font-size: 16px;
    font-weight: 700;
    padding-top: 15px;
    margin-bottom: 0;
    div {
      color: $form-text;
      font-size: 14px;
      font-weight: 500;
    }
  }
  legend + p {
    margin-top: 1em;
  }
}

mat-icon.inline {
  font-size: 18px !important;
  display: inline !important;
}

mat-icon.warning {
  color: $orange;
}

mat-icon.smaller-icon {
  font-size: 22px; //standard mat-icon is font 24 height 24, but it's too big next to text
  height: 22px; //smaller than 22 starts to look pixelated
}

.mini-call-to-action {
  padding: 6px 7px;
  border-radius: 2px;
  background-color: $light-gray;
}

.info-box {
  margin: 8px 20px;
  padding: 10px 15px;
  background-color: $light-gray;
}

sn-scroll-spy-section {
  display: block;
}

mat-form-field .mat-form-field-label {
  font-size: 16px;
  @include dark-color;
  font-weight: 600;
}

.mat-checkbox-layout .mat-checkbox-label:not(.opt-in *) {
  font-size: 14px;
  color: $dark-text;
  padding-right: 8px;
}

.mat-error + .mat-radio-button {
  margin-top: 10px !important;
}

.mat-radio-checked .mat-radio-label-content {
  font-weight: 600;
  & > p,
  & > div {
    font-weight: normal;
  }
}

.mat-radio-group.yes-no {
  float: right;
  margin-top: -2px;
  & + label {
    font-size: 18px;
    color: black;
    font-weight: 600;
    width: 70%;
    line-height: 1.3;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-checkbox-background {
  @include dark-bg;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-primary .mat-radio-inner-circle {
  @include dark-border-color;
}

.equal-widths {
  table-layout: fixed;
  width: 100%;
}
.first-col-longer {
  th:first-child {
    width: 140px;
  }
}

.last-col-longer {
  th:last-child {
    width: 140px;
  }
}

.mat-table {
  width: 100%;
  line-height: 1.1;
  background-color: transparent !important;
  .mat-row.hover-color:hover > .mat-cell {
    background-color: $selected-bg-light-blue;
  }
  .mat-cell,
  .mat-footer-cell,
  .mat-header-cell {
    border-bottom-color: $line-gray-table;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    &:not(:first-child):not(.opt-in *) {
      padding-left: 15px;
    }
    a {
      color: $light-blue;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .mat-header-row {
    .mat-header-cell {
      button {
        text-align: left;
      }
    }
    .mat-header-cell:not(.opt-in *) {
      vertical-align: bottom;
      height: 32px !important;
      white-space: nowrap;
      padding-bottom: 3px;
      font-weight: 600;
      font-size: 11px;
      line-height: 1.1;
      color: $dark-text;
    }
  }

  &.no-bottom-line {
    .mat-row:last-child .mat-cell {
      border-bottom-width: 0 !important;
    }
  }
  &.wide-padding,
  &.simple-table {
    .mat-cell,
    .mat-footer-cell,
    .mat-header-cell {
      &:not(:first-child) {
        padding-left: 25px;
      }
    }
  }
  &.no-margins,
  &.equal-widths {
    .mat-cell,
    .mat-footer-cell,
    .mat-header-cell {
      &:not(:first-child) {
        padding-left: 0;
      }
    }
  }
  &.no-margins,
  &.simple-table {
    .mat-cell,
    .mat-footer-cell,
    .mat-header-cell {
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
  &.with-detail-row {
    .detail-row {
      display: none;
    }
    .mat-row {
      .mat-column-expand .mat-icon {
        vertical-align: bottom;
      }
      &.expanded {
        .mat-cell {
          border-bottom-width: 0 !important;
        }
        .mat-column-expand .mat-icon {
          transform: scaleY(-1);
        }
        & + tr.detail-row {
          display: table-row;
        }
      }
    }
  }
}
.mat-column-noItems,
.mat-column-isLoading,
.no-items {
  text-align: center;
  color: $muted-text;
  font-style: italic;
}

form > footer {
  text-align: center;
  margin: 5px;
  button.mat-button {
    &:not(.opt-in *) {
      font-size: 18px;
      font-weight: 600;
      color: white;
      padding: 6px 50px;
      margin: 10px;
      background-color: $dark-button-bg;
      &:last-child {
        @include dark-bg;
      }
    }
  }
}

.mat-form-field > .mat-form-field {
  display: block;
}
.flex-fields {
  display: flex;
  > .mat-form-field {
    flex: 1 1 0;
    -ms-flex: 1;
    margin-right: 10px;
    @media screen {
      @include media-breakpoint-down(xs) {
        margin-right: 0;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &.reversed {
    flex-direction: row-reverse;
    > .mat-form-field {
      margin-right: 0;
    }
  }
}

.mat-tooltip {
  cursor: help;
}

.no-underlines {
  .mat-form-field-underline {
    display: none;
  }
}

lib-edit-phone {
  .mat-form-field {
    flex: 1 1 auto;
    margin-right: 10px;
    .mat-form-field-label,
    .mat-form-field-label-wrapper {
      overflow: visible;
    }
    &:first-child {
      flex: 0 1 65px;
      width: 65px;
    }
    &:first-child:not(.mat-focused) {
      .mat-form-field-label-wrapper {
        display: none;
      }
    }
    &:nth-child(2):not(.no-adjust) .mat-form-field-label-wrapper {
      left: -75px;
    }
    &.mat-focused:first-child + .mat-form-field {
      .mat-form-field-label-wrapper {
        display: none;
      }
    }
    &:last-child {
      flex: 0 1 50px;
      width: 50px;
      margin-right: 0;
    }
  }
}

@media screen {
  @include media-breakpoint-down(xs) {
    lib-edit-phone {
      .mat-form-field-label-wrapper {
        display: inline !important;
        left: 0 !important;
      }
      .mat-form-field:nth-child(2) {
        max-width: none;
      }
    }
  }
}

.flex-fields-split {
  flex-wrap: wrap;
  > .mat-form-field {
    flex: 0 0 calc(50% - 10px);
    &:nth-child(2) {
      margin-right: 0;
    }
  }
}

//use .grid with grid-template-columns: 50% 50%; -ms-grid-columns: 50% 50%;
.grid {
  display: -ms-grid;
  display: grid;
  grid-gap: 0 10px;
}

.flex-fields-gray {
  border: 1px solid $underline-gray;
  border-radius: 2px;
  margin: 10px 0;
  padding: 0 10px;
  background-color: $light-gray-alt;
}

.flex-fields-info {
  > .mat-form-field {
    flex: 1 1 0;
    &:first-child,
    &:last-child {
      flex: 1 1 75px;
    }
  }
}

@media screen {
  @include media-breakpoint-down(xs) {
    .flex-fields,
    .xs-collapse {
      display: flex !important;
      flex-wrap: wrap;
      .mat-form-field,
      lib-edit-phone-redesign {
        flex: 100% !important;
      }
    }
  }
}

.question-text {
  em {
    color: red;
    font-style: normal;
  }
}

.question {
  padding: 15px 0;
  font-size: 16px;

  .explanation {
    margin-top: 10px;
    @include dark-color;
    background-color: $light-gray;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.2;
    > span {
      display: block;
      margin-bottom: 5px;
    }
    span span {
      font-weight: normal;
    }
    textarea {
      width: 100%;
      min-height: 5em;
      margin-bottom: 5px;
    }
  }
}

.answer-review {
  padding: 0 50px 0 0;
  position: relative;
  &:after {
    content: 'Yes';
    font-size: 16px;
    font-weight: 600;
    position: absolute;
    top: 0;
    right: 5px;
  }
  h3 {
    font-size: 16px;
  }
}

.highlight-box {
  @include dark-border(2px);
  border-radius: 5px;
  padding: 13px;
  margin: 20px 0;
  h3 {
    position: relative;
    top: -13px;
    margin: 0 0 2px -13px;
    display: inline-block;
    padding: 3px 13px;
    @include dark-bg;
    color: white;
    font-weight: normal;
    font-size: 18px;
    border-radius: 0 0 5px 0;
  }
}

.mat-dialog-content p {
  line-height: 1.2;
}

.mat-dialog-actions {
  justify-content: center;
  margin-bottom: -6px !important;
  &.reverse {
    flex-direction: row-reverse;
    .mat-button-base + .mat-button-base {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}

ul.vanilla {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin-bottom: 1em;
  }
  margin-bottom: 1em;
}

p + ul.vanilla,
p + ul {
  margin-top: -0.5em;
}

.multiline-checkboxes {
  label {
    white-space: normal;
    padding-left: 30px;
    position: relative;
    .mat-checkbox-label {
      line-height: 1.2 !important;
    }
    .mat-checkbox-inner-container {
      position: absolute;
      top: 0;
      left: 5px;
    }
  }
}

.capitalize {
  text-transform: capitalize;
}

.selector {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;

  .mat-select {
    font-size: 14px;
  }

  .mat-form-field,
  &.mat-form-field {
    text-transform: none;
  }

  .mat-form-field-infix {
    border: 1px solid $underline-gray;
    border-radius: 2px;
    padding: 0;
    background-color: $light-gray-alt;
  }

  .mat-form-field-appearance-standard .mat-select-arrow-wrapper,
  &.mat-form-field-appearance-standard .mat-select-arrow-wrapper {
    border-left: 1px solid $underline-gray;
  }

  .mat-select-value {
    min-width: 95px;
    max-width: 95px;
    text-overflow: ellipsis;
    width: auto;
    padding: 5px 9px 5px 7px;
  }

  .mat-select-arrow {
    margin: 1px 7px 0 7px;
  }

  .mat-form-field-appearance-standard .mat-form-field-underline,
  &.mat-form-field-appearance-standard .mat-form-field-underline {
    display: none;
  }
}

app-firm-type-selector.selector {
  .mat-select-value {
    min-width: 50px;
    max-width: 50px;
  }
}

td .selector .mat-form-field {
  top: 2px;
}

.mat-menu-panel {
  min-height: auto !important;
}

.mat-menu-panel button {
  width: 100%;
  text-align: left;
  border-radius: 0;
}

.html-tooltip {
  position: relative;
  cursor: help;
  > div {
    display: none;
    padding: 10px;
    background-color: black;
    color: white;
    line-height: 1.3;
    h4 {
      font-weight: normal;
      text-transform: uppercase;
    }
    h5 {
      font-size: 14px;
      padding: 0;
      margin: 0.5em 0 0.3em;
    }
  }
  &:hover {
    > div {
      display: block;
      position: absolute;
      bottom: 1.5em;
      width: 300px;
    }
  }
}

.lookup-result-status,
.lookup-result-info {
  margin-left: 5px;
  font-size: 14px;
}

.lookup-result-info {
  color: $muted-text;
  .highlight {
    text-decoration: underline;
    font-weight: normal;
    color: $dark-text;
  }
}

nav + p {
  margin-top: 1.5em;
}
