@media print {
  body {
    background-color: white !important;
    /* force background graphics to print chrome, safari */
    -webkit-print-color-adjust: exact;
  }
  header,
  footer,
  nav,
  div.info,
  hideme,
  #save-later,
  #legal {
    display: none !important;
  }
  app-main > .app-section {
    max-width: none !important;
    background-color: transparent !important;
    > article {
      max-width: none !important;
      margin: 0 !important;
      box-shadow: none !important;
    }
  }
}
