/* You can add global styles to this file, and also import other style files */
@import 'theme';
@import 'variables';
@import 'spacing';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/reboot';
/* @import "~bootstrap/scss/grid"; */
@import '~bootstrap/scss/utilities';
@import 'form';
@import 'blank';
@import 'print';
@import 'status-colors';
@import 'header';
@import 'no-lastpass';
@import 'side-links';
@import 'typography';

@include tailwind-like-helper-classes();

body {
  font-family: 'Source Sans Pro', sans-serif;
  color: $dark-text;
  background-color: $main-gray-bg;
}

a {
  @include dark-color;
  &:hover,
  &:active {
    @include dark-color;
  }
  &.muted {
    pointer-events: none;
  }
}

h1,
h2,
h3,
h4 {
  line-height: 1.3;
}

ul {
  padding-inline-start: 0;
}

app-main > header > section > div,
app-main > main > div,
app-main > footer > div,
.app-section {
  min-width: 600px; //NOte: below 600px width most fields switch to column layout. Setting min width to even 480px might be okay
  max-width: 1256px;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto;
  display: block;
}

app-main > header > section:first-child {
  background-color: $body-bg;
  text-align: right;
  font-size: 12px;
  .login-email {
    @include dark-color;
    display: inline-block;
    margin: 0 1em;
  }
}

app-main > footer {
  padding: 10px 30px;
  color: white;
  font-size: 14px;
  @include dark-bg;
  > div {
    display: flex;
    flex-wrap: wrap;
    > span {
      word-wrap: no-wrap;
      &:first-child {
        margin-right: auto;
      }
      &:last-child {
        font-weight: 600;
      }
    }
  }
  a {
    color: white;
    margin-left: 20px;
    word-wrap: no-wrap;
    &:hover {
      color: white;
    }
  }
}

.mat-autocomplete-panel:not(.scroll) {
  max-height: 90vh !important;
}

.mat-select-panel.skip-first .mat-option:first-child {
  display: none;
}

.mat-panel-class {
  white-space: pre-wrap;
}

.flex-justify {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.faux-modal {
  display: block;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 600px;
}

p:not(.note) + .note,
h2 + .note {
  margin-top: -1em;
}

.note-area {
  margin-top: 15px;
  color: $form-text;
  border-top: 1px solid $form-text;
  padding-top: 10px;
  ol {
    padding-left: 20px;
  }
  li {
    font-size: 13px;
    margin-bottom: 10px;
  }
}

.note {
  font-size: 14px;
  color: $form-text;
  line-height: 1.3;
  mat-icon {
    display: inline;
    position: relative;
    top: 5px;
  }
}
