@import 'typography-mixins';

.type-display-1 {
  @include type-display-1;
}

.type-display-2 {
  @include type-display-2;
}

.type-display-3 {
  @include type-display-3;
}

.type-display-4 {
  @include type-display-4;
}

.type-headline {
  @include type-headline;
}

.type-title {
  @include type-title;
}

.type-subtitle-1 {
  @include type-subtitle-1;
}

.type-subtitle-2 {
  @include type-subtitle-2;
}

.type-p-1 {
  @include type-p-1;
}

.type-p-2 {
  @include type-p-2;
}

.type-caption {
  @include type-caption;
}

.type-overline {
  @include type-overline;
}

.opt-in {
  & .mat-button-base {
    @include SourceSansPro600;
    font-size: 15px;
    line-height: 36px;
    letter-spacing: 0.75px;
    text-transform: capitalize;
  }

  & .mat-input-element {
    @include SourceSansProNormal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
}

.mat-button-base {
  &.icon-right,
  &.icon-left {
    .mat-icon {
      position: relative;
      top: -2px;
    }
  }
  &.icon-right .mat-icon {
    margin-left: 3px;
  }
  &.icon-left .mat-icon {
    margin-right: 3px;
  }
}
