.status-box {
  color: white;
  display: inline-block;
  width: 14px;
  height: 14px;
  font-weight: 800;
  border-radius: 2px;
  font-size: 8px;
  position: relative;
  top: 2px;
  margin-right: 3px;
  &:after {
    line-height: 0;
    position: absolute;
    text-align: center;
    top: 7px;
    width: 14px;
  }
}

.status-word {
  text-transform: uppercase;
}

@each $status, $color in $status-colors {
  .status-word.status-#{$status} {
    color: $color;
  }

  .status-box.status-#{$status} {
    background-color: $color;
    &:after {
      content: attr(data-status-abbreviation);
    }
  }

  .status-pill.status-#{$status} {
    background-color: $color;
    border-color: $color;
  }
}

tr.mat-row.status-row {
  background-color: $light-gray-alt;
  height: 30px;
  > .mat-cell {
    font-size: 12px;
    font-weight: 700;
  }
  &.stat-row {
    border: 1px solid $muted-text;
  }
}
