$dark-color: var(--dark-color);
@mixin dark-bg {
  background-color: #0e4e74;
  background-color: var(--dark-color);
}
@mixin dark-color {
  color: #0e4e74;
  color: var(--dark-color);
}
@mixin dark-border-color {
  border-color: #0e4e74;
  border-color: var(--dark-color);
}
@mixin dark-border($width: 1px) {
  border: $width solid #0e4e74;
  border: $width solid var(--dark-color);
}
@mixin accent-color-and-bg {
  background-color: #0e4e74;
  background-color: var(--accent-color);
  color: white;
  color: var(--accent-font-color);
  font-weight: var(--accent-font-weight);
}
$light-blue: #4d8eb2;
$main-nav-off-blue: #aac1ce;
$main-nav-muted-blue: #4a7a96;
$comment-indicator-blue: #8da5ba;
$selected-bg-light-blue: #e6edf1;
$dialog-content-bg-light-blue: #f6f8fa;
$dark-blue: #0e4e74;
$form-text: #7a7a7a;
$dark-text: #4a4a4a;
$muted-text: #b9b9b9;
$gray-text: #6e6e6e;
$body-bg: #d6d6d6;
$header-gray: #ebebeb;
$construction-bg-gray: #e8e8e8;
$line-gray: #e0e0e0;
$line-gray-darker: #b8b8b8;
$line-gray-table: #d6d6d6;
$line-gray-alt: #e5e5e5;
$line-gray-dark: #979797;
$label-gray: #8b8b8b;
$alt-cancel-button-bg: #8b8b8b;
$underline-gray: #cccccc;
$progress-fill: #2196f3;
$ncarb: #1ba6f4;
$progress-bg: #c4c4c4;
$light-gray: #f2f2f2;
$light-gray-alt: #f7f7f7;
$main-gray-bg: #fafafa;
$form-bg: #e6edf1;
$green: #00c25b;
$money: #83b040;
$orange: #ff9400;
$dark-button-bg: #7a7a7a;
$error: #f44336;

$pending: #fdeed4;
$alternate-background: #fbeed7;

$gap: 10px;

$status-colors: (
  new: #00b861,
  open: #ff9400,
  hold: #ff001f,
  submitted: #4a90e2,
  pending: #6c66ce,
  closed: $dark-text,
  active: #00c25b,
  due: #00c25b,
  emeritus: #00c25b,
  emeritusdue: #00c25b,
  delinquent: #ff9000,
  probation: #ff9000,
  suspended: #ff9000,
  limited: #ff9000,
  expired: #ff0000,
  expiredemeritus: #ff0000,
  revoked: #ff0000,
  inactive: $dark-text,
  retired: $dark-text,
  deceased: $dark-text,
  unlicensed: $dark-text,
);

$grid-breakpoints: (
  // handset portrait (small, medium, large) | handset landscape (small)
  xs: 0,
  // handset landscape (medium, large) | tablet portrait(small, large)
  sm: 600px,
  //  tablet landscape (small, large)
  md: 960px,
  // laptops and desktops
  lg: 1280px,
  // large desktops...
  xl: 1600px
);

$container-max-widths: (
  sm: 600px,
  md: 960px,
  lg: 1280px,
  xl: 1600px,
);

%mat-button-large {
  font-size: 18px;
  font-weight: normal;
  padding-top: 4px;
  padding-bottom: 4px;
}
