.side-links {
  a {
    border-radius: 0 25px 25px 0;
    border-style: solid;
    border-width: 0 0 0 6px;
    border-color: transparent;
    height: 40px !important;
    line-height: 1.2;
    &.indent {
      padding-left: 10px;
    }
    &.active {
      @include dark-border-color;
      background-color: $form-bg;
    }
    &.muted {
      color: $muted-text;
      &:hover {
        border-left-color: $muted-text;
      }
      &:active {
        pointer-events: none;
      }
    }
  }
}
