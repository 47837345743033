@use '@angular/material' as mat;
@use 'sass:map';

@import 'legacy-theme';
@import 'ls-theme';
@import '../../node_modules/@angular/material/_theming.scss';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$custom-typography: mat.define-typography-config(
  $font-family: '"Source Sans Pro", "sans-serif"',
);
@include mat.core($custom-typography);

.opt-in {
  @include mat.all-component-themes($ls-theme);
  @include ls-status-colors($ls-theme);
  @include ls-supplemental-colors($ls-theme);
  @include material-overrides($ls-theme);

  color: getTextColor($ls-theme); //should we add body.opt-in too?
}

//using mat.all-component-colors instead of mat.all-component-themes
//to remove warning
@include mat.all-component-colors($legacy-theme);
