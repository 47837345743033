app-main > header > section:last-child {
  color: white;
  @include dark-bg;
  div {
    display: flex;
    flex-wrap: wrap;
    min-height: 85px;
    img {
      flex: 0 0 154px;
      width: 154px;
      min-height: 56px;
      margin-top: 15px;
    }
    nav {
      flex: 1 1 0;
      align-self: flex-end;
      display: inline-block;
      margin-right: auto;
      padding-left: 40px;
      a {
        color: $main-nav-off-blue;
        font-size: 14px;
        padding: 3px 7px;
        margin: 0 10px 0 0;
        display: inline-block;
        text-decoration: none;
        border-bottom: 2px solid transparent;
        border-bottom-width: 4px;
        &:hover,
        &.active {
          color: white;
          border-color: white;
        }
        &.muted {
          color: $main-nav-muted-blue;
        }
      }
      a.search {
        border-bottom-width: 0;
        padding: 0 0 0 7px;
        mat-icon {
          line-height: 14px;
        }
      }
    }
    h1 {
      font-size: 18px;
      color: white;
      text-transform: uppercase;
      letter-spacing: 3px;
      font-weight: 600;
      align-self: flex-end;
    }
  }
}
