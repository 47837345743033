.app-section.application {
  article#main {
    /* #application-router-container padding trick to support wizard-steps */
    padding: 0;
  }
  max-width: 966px;
  @include media-breakpoint-down(xs) {
    padding: 0;
  }
  > header {
    margin: 20px 0 -20px;
    @include media-breakpoint-down(xs) {
      margin: 20px 20px -20px;
    }
    h1 {
      font-size: 24px;
      @include dark-color;
      margin-bottom: 0;
    }
    h2 {
      font-size: 20px;
      color: black;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }
}

#application-router-container {
  overflow: hidden;
  > * {
    display: block;
    padding: 0 24px 24px;
  }
}

nav#app-tabs {
  @include dark-bg;
  &.mat-tab-nav-bar {
    border: 0px;
  }

  .mat-tab-links {
    justify-content: space-around;
  }

  .mat-tab-link {
    padding: 0 0 10px;
    min-width: auto;
    height: 42px;

    font-size: 12px;
    font-weight: 700;
    color: white;
    opacity: 0.5;
    text-transform: uppercase;
    line-height: 1;
    padding-top: 9px;
    &:hover {
      text-decoration: none;
    }
    &.mat-tab-label-active {
      opacity: 1;
    }
  }

  &.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    height: 5px;
    background-color: white;
  }
}

#save-later {
  margin: 30px 0;
  text-align: center;
  color: black;
  font-size: 16px;
  button.save-application {
    padding: 6px 12px;
    font-size: 17px;
  }
}

button.save-application {
  @include accent-color-and-bg;
  font-weight: 400;
  font-size: 16px;
}

#legal {
  margin: 30px 0;
  @include media-breakpoint-down(xs) {
    margin: 30px 20px;
  }
  border-top: 1px solid $dark-text;
  padding-top: 25px;
  font-size: 16px;
  color: $dark-text;
  a {
    font-weight: 700;
  }
}
