@mixin SourceSansProNormal {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
}

@mixin SourceSansPro300 {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 300;
}

@mixin SourceSansPro600 {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
}

@mixin type-display-1 {
  @include SourceSansProNormal;
  font-size: 37px;
  line-height: 40px;
  letter-spacing: 0.25px;
  text-transform: capitalize;
}

@mixin type-display-2 {
  @include SourceSansProNormal;
  font-size: 52px;
  line-height: 56px;
}

@mixin type-display-3 {
  @include SourceSansPro300;
  font-size: 66px;
  line-height: 72px;
  letter-spacing: -0.5px;
}

@mixin type-display-4 {
  @include SourceSansPro300;
  font-size: 105px;
  line-height: 112px;
  letter-spacing: -1.5px;
}

@mixin type-headline {
  @include SourceSansProNormal;
  font-size: 26px;
  line-height: 24px;
}

@mixin type-title {
  @include SourceSansPro600;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 0.15px;
}

@mixin type-subtitle-1 {
  @include SourceSansPro600;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.15px;
}

@mixin type-subtitle-2 {
  @include SourceSansPro600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.1px;
}

@mixin type-p-1 {
  @include SourceSansProNormal;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

@mixin type-p-2 {
  @include SourceSansProNormal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

@mixin type-caption {
  @include SourceSansProNormal;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.4px;
}

@mixin type-overline {
  @include SourceSansProNormal;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
