/* You can add global styles to this file, and also import other style files */
@import '../../../src/styles/main'; /* TODO: not sure why Angular 9 is having a problem finding this through stylePreprocessorOptions/includePaths */
@import '../../../src/styles/application'; /* relative for ng test build */

app-main {
  margin: 0 auto;
  display: block;
}

article {
  h1 {
    font-weight: 600;
    font-size: 28px;
  }
}

article#main {
  max-width: 554px;
  position: relative;
  overflow: hidden;
  background-color: white;
  margin: 30px auto;
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.075);
  padding: 24px;
  color: $dark-text;
  min-height: calc(100vh - 413px);
  @include media-breakpoint-down(xs) {
    box-shadow: none;
  }
  h2 {
    font-weight: normal;
    font-size: 20px;
  }
  h3 {
    font-size: 18px;
  }
}

article#portal {
  max-width: 980px;
  margin: 30px auto;
  padding: 0 20px;
  color: $dark-text;
  min-height: calc(100vh - 413px);
}
