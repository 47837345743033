@use 'sass:map';

//"$sizes" is a bootstrap variable
$pad-sizes: (
  xxs: 2px,
  xs: 4px,
  small: 8px,
  medium: 16px,
  large: 32px,
  x-large: 64px,
);

@function getSizesPx($size-name) {
  $size: map.get($pad-sizes, $size-name); //$pad-sizes.status
  @return $size;
}

@mixin tailwind-like-helper-classes() {
  @each $size-name, $size in $pad-sizes {
    $styleHelper: (
      p: 'padding',
      m: 'margin',
    );
    @each $css-prefix, $style in $styleHelper {
      .#{$css-prefix}-#{$size-name} {
        #{$style}: $size;
      }

      .#{$css-prefix}x-#{$size-name} {
        #{$style}-left: $size;
        #{$style}-right: $size;
      }

      .#{$css-prefix}y-#{$size-name} {
        #{$style}-top: $size;
        #{$style}-bottom: $size;
      }

      .#{$css-prefix}t-#{$size-name} {
        #{$style}-top: $size;
      }

      .#{$css-prefix}r-#{$size-name} {
        #{$style}-right: $size;
      }

      .#{$css-prefix}l-#{$size-name} {
        #{$style}-left: $size;
      }

      .#{$css-prefix}b-#{$size-name} {
        #{$style}-bottom: $size;
      }
    }

    .h-#{$size-name} {
      height: $size;
    }

    .gap-#{$size-name} {
      gap: $size;
    }

    .w-#{$size-name} {
      width: $size;
    }

    .border-#{$size-name} {
      border-width: $size;
    }
  }
}

@mixin stack($size: medium, $element: padding) {
  padding: map.get($pad-sizes, $size) 0;
}

@mixin stack-margin($size: medium) {
  margin: map.get($pad-sizes, $size) 0;
}

@mixin inline($size: medium) {
  padding: 0 map.get($pad-sizes, $size);
}

@mixin inset($size: medium) {
  padding: map.get($pad-sizes, $size);
}
