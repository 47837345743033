@use '@angular/material' as mat;
@use 'sass:map';
@import 'typography-mixins';
@import 'spacing';

@function buildLSMaterialTheme() {
  $light-text: #ffffff;
  $text: #000000;
  $outline: #e0e0e0;
  $background-color: #f5f5f5;
  $primary-colors: (
    50: #e3f1fa,
    100: #badefc,
    200: #8ecbfb,
    300: #60b6f8,
    400: #3aa6f8,
    500: #0997f7,
    600: #0889e9,
    700: #0377d6,
    800: #0066c4,
    900: #0048a5,
    contrast: (
      50: $text,
      100: $text,
      200: $text,
      300: $text,
      400: $text,
      500: $text,
      600: $text,
      700: $light-text,
      800: $light-text,
      900: $light-text,
    ),
  );
  $palette: mat.define-palette($primary-colors, 700, 200, 900);
  $color-settings: (
    color: (
      primary: $palette,
      accent: $palette,
      warn:
        mat.define-palette(
          (
            500: #a12027,
            contrast: (
              500: #f5e7e9,
            ),
          ),
          500,
          500,
          500
        ),
    ),
    status: (
      error: (
        color: #a12027,
        contrast: #f5e7e9,
      ),
      success: (
        color: #3b773a,
        contrast: #edfaef,
      ),
      warning: (
        color: #b44d1e,
        contrast: #fef6da,
      ),
      pending: (
        color: #504d8f,
        contrast: #f0f0fa,
      ),
      submitted: (
        color: #40649a,
        contrast: #eff4fc,
      ),
      inactive: (
        color: $text,
        contrast: $background-color,
      ),
    ),
    supplemental: (
      //anything in here you can use by adding 'ls-' example: ls-text ls-text-grey
      text: (/* If we add extra css properties, they will be emitted as well. */ color: $text),
      text-grey: (
        color: #616161,
      ),
      hyperlink: (
        color: #0377d6,
      ),
      surface: (
        color: #ffffff,
      ),
      background-grey: (
        background-color: $background-color,
      ),
      outline: (
        outline-color: $outline,
        outline-style: dashed,
        outline-width: 3px,
      ),
      outline-top: (
        border-top-color: $outline,
        border-top: 1px solid $outline,
      ),
      outline-bottom: (
        border-bottom-color: $outline,
        border-bottom: 1px solid $outline,
      ),
      outline-border: (
        border: 1px solid $outline,
      ),
    ),
  );

  $theme: mat.define-light-theme($color-settings);

  // Merge in black foreground color into the LS foreground object
  $foreground: map.get($theme, foreground);
  $foreground: map.merge(
    $foreground,
    (
      text: $text,
      divider: $outline,
      dividers: $outline,
    )
  );

  $color: map.merge(
    map.get($theme, color),
    (
      foreground: $foreground,
    )
  );

  /*
  Now merge the LS  foreground object back into the LS theme
  so the LS theme has the new black foreground color
  */
  $theme: map.merge(
    $theme,
    (
      foreground: $foreground,
      color: $color,
    )
  );
  @return $theme;
}

$ls-theme: buildLSMaterialTheme();

@function getPrimaryColor($theme: $ls-theme) {
  @return mat.get-color-from-palette(map.get(map.get($theme, color), primary)); //$theme.color.primary.default
}

@function getSupplementalColor($style-name, $theme: $ls-theme) {
  $supplemental-styles: map.get($theme, supplemental);
  $style: map.get($supplemental-styles, $style-name);
  // Assume the color is the first property listed.  This allows for
  // outline color and other color property values to be returned.
  $color-property-value: nth(map-values($style), 1);
  @return $color-property-value;
}

@function getTextColor($config) {
  $foreground: map.get($config, foreground);
  @return mat.get-color-from-palette($foreground, text);
}

//quick way to get status color or status contrast
@function getStatusColor($name, $color-or-contrast: color) {
  $status-colors: map.get($ls-theme, status); //theme.status
  $status: map.get($status-colors, $name); //theme.status.$name
  @return map.get($status, $color-or-contrast); //theme.status.$name.[color|contrast]
}

@mixin ls-status-colors($theme) {
  $status-colors: map.get($theme, status); //theme.status
  @each $name, $color-contrast in $status-colors {
    $actual-color: map.get($color-contrast, color); //theme.status.[name].color
    $contrast: map.get($color-contrast, contrast);
    .#{$name}-color {
      //ie. class="success-color"
      background-color: $actual-color;
      color: $contrast;
    }
    .on-#{$name}-color {
      //ie. class="on-success-color"
      background-color: $contrast;
      color: $actual-color;
    }
    .on-#{$name}-text {
      //ie. class="on-success-text"
      color: $actual-color;
    }
    .#{$name}-border {
      border: 1px solid $contrast;
      border-radius: 4px;
    }
    .on-#{$name}-border {
      border: 1px solid $actual-color;
      border-radius: 4px;
    }
  }
}

@mixin ls-supplemental-colors($theme) {
  $supplemental-styles: map.get($theme, supplemental);
  @each $name, $style in $supplemental-styles {
    .ls-#{$name} {
      @each $property, $value in $style {
        #{$property}: $value;
      }
    }
  }
}

@mixin material-overrides($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $primary: map.get($config, primary);

  $primary-default: mat.get-color-from-palette($primary, default);
  $hoverBg: rgba($primary-default, 0.04);

  .mat-form-field-should-float .mat-form-field-label {
    color: $primary-default; //usually set to foreground.secondary-text
  }

  mat-form-field {
    &.readonly {
      .mat-form-field-underline,
      .mat-form-field-subscript-wrapper,
      //.mat-form-field-suffix, //we want to show the eyeball for SSN and visa
      .mat-form-field-prefix {
        display: none; //hide underline, hint text, and icon if field is readonly
      }
    }

    &.remove-hint-pad .mat-form-field-wrapper {
      //the mat-form-field includes a padding if there is hint text. If you don't have hint text, you can turn of this padding.
      padding-bottom: 0;
    }
  }

  $divider: mat.get-color-from-palette($foreground, divider);
  $disabled: mat.get-color-from-palette($foreground, disabled);

  //seems like this is the right level to override mat-form-field-disabled
  //https://github.com/angular/components/blob/6883100b4cfea4c822a5173535d7e710cfcac580/src/material/legacy-form-field/_form-field-outline-theme.scss#L59
  .mat-form-field-disabled {
    .mat-form-field-outline {
      .mat-form-field-outline-start,
      .mat-form-field-outline-end {
        border-left-color: $disabled;
        border-right-color: $disabled;
        border-bottom-color: $disabled;
        border-top-color: $disabled;
      }
    }
    &.mat-form-field-should-float {
      //when the label IS floated it has class mat-form-field-should-float
      .mat-form-field-outline-gap {
        border-left-color: $disabled;
        border-right-color: $disabled;
        border-bottom-color: $disabled;
      }
    }
    &.mat-form-field-hide-placeholder {
      //when the label is NOT floated it has class mat-form-field-hide-placeholder
      .mat-form-field-outline-gap {
        border-left-color: $disabled;
        border-right-color: $disabled;
        border-bottom-color: $disabled;
        border-top-color: $disabled;
      }
    }
    .mat-form-field-suffix {
      color: $disabled;
    }
  }

  .mat-dialog-container {
    @include inset(large);
  }

  .mat-table {
    border-width: 1px;
    border-color: mat.get-color-from-palette($foreground, divider);
    border-style: solid;
  }

  .mat-header-cell {
    @include type-caption;
  }

  mat-tab-group {
    &.mat-tab-group.mat-primary .mat-ink-bar {
      background-color: $primary-default;
    }

    .mat-tab-label mat-icon {
      margin-right: 8px;
    }

    .mat-tab-label:hover {
      text-decoration-color: $primary-default;
    }
  }

  .mat-tab-nav-bar,
  .mat-tab-header {
    border-bottom: none;
  }

  .mat-radio-label-content {
    padding-left: 6px;
    @include type-p-2;
  }

  .mat-tab-label,
  .mat-chip,
  .mat-cell,
  .mat-menu-item,
  .mat-checkbox-label {
    @include type-p-2;
  }

  //this strange syntax makes a second css class ".opt-in.mat-menu-panel"
  //needed because the mat-menu-panel class is on same element at the opt-in class
  .mat-menu-panel#{if(&, ', &.mat-menu-panel', '')} {
    min-width: 220px;
    max-width: 455px;
    margin-bottom: 16px; //leave space below menu
    max-height: calc(100vh - 64px); //should be a little smaller so it leaves some space above and below
  }

  .mat-select-panel#{if(&, ', &.mat-select-panel', '')} {
    margin-bottom: 16px; //leave space below menu
    //max-height could not override the height of select. default is 256px
  }

  .mat-menu-item {
    height: 44px;
  }

  .mat-paginator {
    background: transparent;
  }

  .mat-form-field-subscript-wrapper {
    margin-top: 8px;
  }

  // placing <mat-datepicker ls-style-opt-in> generates => mat-calendar.opt-in parent
  .mat-calendar-header {
    // Month/Year dropdown button and icon
    button.mat-focus-indicator.mat-calendar-period-button.mat-button.mat-button-base {
      color: $primary-default;
    }
    svg.mat-calendar-arrow {
      fill: $primary-default;
    }
  }
  .mat-calendar-content {
    // selected date / month / year
    div.mat-calendar-body-selected {
      background-color: $primary-default;
      color: mat.get-color-from-palette($primary, default-contrast);
    }
  }

  /* Mat-Vertical-Stepper */
  .mat-step-header.mat-vertical-stepper-header {
    height: 32px;
    &:hover {
      background-color: $hoverBg;
    }
    div.mat-step-label {
      @include type-p-2;
    }
    .mat-step-icon-content {
      @include type-subtitle-1;
    } // step #

    /* completed-success */
    .mat-step-icon-state-edit {
      background-color: transparent;

      .mat-icon.material-icons.ng-star-inserted {
        // replaces edit icon with check
        color: transparent;
        position: relative;
        left: -15px;
        &::before {
          content: 'check';
          color: $primary-default;
          position: relative;
          left: 15px;
          font-weight: bold;
        }
      }
    }
    //mat-step-icon-state-number mat-step-icon-selected
    /* current */
    .mat-step-icon-selected {
      div.mat-step-label {
        @include type-subtitle-2;
      }
    }
    /* future */
    .mat-step-icon-state-number:not(.mat-step-icon-selected) {
      border: 2px solid $primary-default;
      color: $primary-default;
      background-color: transparent;
    }
  }

  div.mat-vertical-content-container {
    .mat-stepper-next {
      margin-left: 8px;
    }
    .save-and-close {
      float: right;
      border: none;
      background-color: transparent;
      color: $primary-default;
      font-weight: 600;
      padding: 0;
      margin: 0;
    }
  }

  .mat-table {
    border: 1px solid getSupplementalColor(outline);

    th.mat-header-cell,
    td.mat-footer-cell {
      padding: 0 0 0 24px;
      @include type-caption;
      &:last-child {
        padding-right: 24px;
      }
    }

    .mat-cell {
      @include type-p-2;
      padding: 0 0 0 24px;
      &:last-child {
        padding-right: 24px;
      }
    }
    .mat-row {
      height: 48px;
      &:hover {
        background-color: $hoverBg;
      }
    }
  }
}
